<template>
  <section>
    <NbNumberedAccordionForm
      :id="'accord-form-receiver'"
      class="mb-2"
      :number="number"
      :title="$t('quotePage.shipDestination')"
      :subTitle="$t('quotePage.pleaseEnterData')"
      :helpText="$t('orderCreatePage.receiverInformation.helpText')"
      :hasError="sectionError.receiver"
      :errors="errors"
      :formInputs="receiverInputs"
      @updateValidFields="$emit('updateValidFields', $event)"
      @invalid="$emit('updateInvalidFields', $event)"
      @formDoneChange="$emit('formDoneChangeReceiver', $event)"
      :formVal.sync="receiver"
    >
    </NbNumberedAccordionForm>
  </section>
</template>

<script>
import NbNumberedAccordionForm from "@/components/forms/NbNumberedAccordionForm.vue";
import { COUNTRIES_RECEIVER_REGEX } from "@/constants.js";

export default {
  name: "NbQuoteInformations",
  components: {
    NbNumberedAccordionForm,
  },
  props: {
    formVal: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    sectionError: {
      type: Object,
      required: false,
    },
    number: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      countries: [
        { value: "AR", text: "Argentina" },
        { value: "BR", text: "Brazil" },
        { value: "CL", text: "Chile" },
        { value: "CO", text: "Colombia" },
        { value: "EC", text: "Ecuador" },
        { value: "MX", text: "Mexico" },
        { value: "PE", text: "Peru" },
        { value: "UY", text: "Uruguay" },
      ],
      receiverCountryConfigurations: {
        countryName: "Brazil",
        countryAlpha2Code: "BR",
        documentName: "CPF/CNPJ",
        documentNameHelpText:
          "Cadastro de Pessoas Físicas (CPF) Cadastro Nacional da Pessoa Jurídica (CNPJ)",
        documentFormat: "123.456.789-01",
        cepFormat: "12345-678",
        mask: ["#####-###"],
        cepLength: "8",
        phoneLength: "0",
        documentMasks: ["###.###.###-##", "##.###.###/####-##"],
      },
    };
  },
  methods: {},
  computed: {
    receiver: {
      get() {
        return this.formVal;
      },
      set(val) {
        this.$emit("update:formVal", val);
      },
    },
    receiverInputs() {
      return [
        {
          required: true,
          id: "customer_country",
          type: "select",
          options: this.countries,
          name: this.$t("country"),
          classes: "mb-3 w-24",
          placeholder: this.$t("country"),
          key: "customer_country",
        },
        {
          required: true,
          id: "customer_postal_code",
          type:
            this.receiverCountryConfigurations.countryAlpha2Code === "BR"
              ? "postalCode"
              : "",
          rules: `length:${
            this.receiverCountryConfigurations.cepLength || "0"
          }`,
          name: this.$t("postalCode"),
          masks: this.receiverCountryConfigurations.mask,
          classes: "mb-3 w-24",
          placeholder: this.receiverCountryConfigurations.cepFormat,
          key: "customer_postal_code",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.postalCodeHelpText",
            {
              country: this.receiverCountryConfigurations.countryName,
              cepFormat: this.receiverCountryConfigurations.cepFormat,
            }
          ),
        },
        {
          required: false,
          disabled: true,
          id: "customer_city",
          name: this.$t("city"),
          classes: "mb-3 w-24",
          placeholder: "",
          key: "customer_city",
        },
        {
          required: false,
          disabled: true,
          id: "customer_state",
          name: this.$t("state"),
          classes: "mb-3 w-24",
          placeholder: "",
          key: "customer_state",
        },
      ];
    },
  },
  watch: {
    "receiver.customer_country": function (newValue) {
      const countryAttributes = COUNTRIES_RECEIVER_REGEX.find(
        ({ iso }) => iso === newValue
      );

      this.receiverCountryConfigurations.countryName =
        countryAttributes.countryName;
      this.receiverCountryConfigurations.countryAlpha2Code = newValue;
      this.receiverCountryConfigurations.documentName =
        countryAttributes.documentName;
      this.receiverCountryConfigurations.documentNameHelpText =
        countryAttributes.documentNameHelpText;
      this.receiverCountryConfigurations.documentFormat =
        countryAttributes.documentFormat;
      this.receiverCountryConfigurations.mask = countryAttributes.mask
        .trim()
        .split(",");
      this.receiverCountryConfigurations.cepFormat =
        countryAttributes.cepFormat;
      this.receiverCountryConfigurations.cepLength = countryAttributes
        ? countryAttributes.maskLength
        : "0";
      this.receiverCountryConfigurations.documentMasks =
        countryAttributes.documentMasks || null;
    },
  },
};
</script>
