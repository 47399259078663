<template>
  <div>
    <NbPageTitle
      :title="$t('quotePage.title')"
      :subTitle="$t('quotePage.subtitle')"
      class="mb-4"
    />
    <NbQuoteReceiverInformations
      id="receiver-information"
      :number="1"
      :sectionError="sectionErros"
      :errors="errors"
      @formDoneChangeReceiver="formDoneChange($event, 'receiver-information')"
      @updateValidFields="updateValidFields($event)"
      @updateInvalidFields="updateInvalidFields($event)"
      :formVal.sync="quote.receiver"
    />

    <NbQuoteShipmentInformation
      id="shipment-information"
      :number="2"
      :sectionError="sectionErros.shipment || sectionErros.boxes"
      :errors="errors"
      @formDoneChangeShipment="formDoneChange($event, 'shipment-information')"
      @updateValidFields="updateValidFields($event)"
      @updateInvalidFields="updateInvalidFields($event)"
      :formVal.sync="quote.shipment"
    />

    <div class="my-3" style="padding: 0px 6rem">
      <Alert
        class="mb-2 text-center py-3 pointer"
        :aling="'center'"
        type="success"
        :message="$t('quotePage.goToOrderCreate')"
        @click="createOrder()"
      />
      <Alert
        v-if="!canGetQuotations"
        class="mb-2 text-center py-3"
        :aling="'center'"
        type="warning"
        :message="$t('orderCreatePage.courierInformation.fillAllOrderInfo')"
      />
    </div>
    <div class="dashed-middle d-flex justify-content-center mb-3">
      <NbButton
        variant="secondary"
        :disabled="!canGetQuotations"
        style="position: relative; z-index: 1"
        @click="getQuotations()"
      >
        <NbIcon
          :icon="isLoadingQuotes ? 'loader' : 'globe'"
          :attributes="{
            class: `mr-1 mb-1 ${isLoadingQuotes ? 'spin' : ''}`,
            width: '1rem',
            height: '1rem',
          }"
        />
        {{ $t("orderCreatePage.courierInformation.getQuote") }}
      </NbButton>
    </div>

    <NbCard
      v-if="showQuotes"
      id="contracts-card"
      class="mb-2"
      :title="$t('orderCreatePage.courier')"
      :subTitle="$t('orderCreatePage.courierInformation.title')"
    >
      <template #body>
        <NbQuote
          id="contract-list"
          :genericMessage="genericMessage"
          :currency="simplifyCurrency(currency)"
          :quotes="quotes"
          :fields="fieldsQuote"
          :formVal.sync="selectedContract"
        />
        <div
          v-if="errorQuoteCreate"
          id="contracts-card"
          class="row justify-content-center px-0 py-2 my-1 mx-0 rounded"
        >
          <div class="col-lg-12 col-md-12 card-0 px-0 pt-2">
            <p class="mt-3 text-danger">{{ errorQuoteCreate }}</p>
          </div>
        </div>
      </template>
    </NbCard>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle";
import NbQuoteReceiverInformations from "./components/NbQuoteReceiverInformations.vue";
import NbQuoteShipmentInformation from "./components/NbQuoteShipmentInformation.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbQuote from "@/components/quote/NbQuote.vue";
import NProgress from "nprogress";

import QuickstartService from "@/services/QuickStartService";
import QuoteService from "@/services/QuoteService";

const quickstartService = new QuickstartService();
const quoteService = new QuoteService();

export default {
  name: "QuotesNew",

  components: {
    NbPageTitle,
    NbQuoteReceiverInformations,
    NbQuoteShipmentInformation,
    NbButton,
    Alert,
    NbIcon,
    NbCard,
    NbFooter,
    NbQuote,
  },
  data() {
    return {
      genericMessage:
        "Please change the value of the items. You can also decide to put another value for the freight, down this page.",
      errors: {},
      loaded: true,
      quickStartLoaded: {
        hs_code: null,
        currency: null,
        contract: null,
        incoterm: null,
        insurance: null,
        origin_country: null,
        category: null,
      },
      quote: {
        receiver: {
          customer_address: "",
          customer_address_complement: "",
          customer_address_number: "",
          customer_address_quarter: "",
          customer_address_reference: "",
          customer_city: "",
          customer_country: "BR",
          customer_created_at: "",
          customer_description: "",
          customer_document_number: "",
          customer_document_type: "CPF",
          customer_email: "",
          customer_full_name: "",
          customer_id: "",
          customer_phone: "",
          customer_platform_id: "",
          customer_postal_code: "",
          customer_seller_id: "",
          customer_state: "",
          customer_updated_at: "",
          remetente_is_cpf: false,
          seller_address: "",
          seller_address_complement: "",
          seller_address_number: "",
          seller_city: "",
          seller_country: "BR",
          seller_email: "",
          seller_name: "",
          seller_phone: "",
          seller_phone_code: "+55",
          seller_phone_number: "",
          seller_state: "",
          seller_tax_number: "",
          seller_website: "",
          seller_zip_code: "",
        },
        shipment: {
          currency: "USD",
          freight_cost: "",
          freight_value: "",
          incoterm: "DDU",
          is_landed_cost: null,
          order_number: "",
          quantity: 1,
          return_insurance: false,
          sales_channel_id: null,
          sales_channel_order_number: "",
          total_value: "0.00",
          volumes_attributes: [
            {
              created_at: "",
              default_choice: true,
              false: "",
              freight_cost: null,
              freight_value: null,
              has_nocuous_items: false,
              height: "",
              id: null,
              length: "",
              null: "",
              platform_id: null,
              seller_id: null,
              total_weight: "",
              true: "",
              updated_at: "",
              volume_name: "",
              volume_type: "",
              weight: "",
              width: "",
              order_items_attributes: [
                {
                  box: "Caixa sapatos",
                  category: "",
                  created_at: "",
                  description: "",
                  freight_cost: "",
                  freight_value: "",
                  hs_code: null,
                  id: null,
                  name: "",
                  origin_country: "",
                  platform_id: null,
                  quantity: 1,
                  seller_id: null,
                  sku: "",
                  total: 0,
                  unit_price: "",
                  updated_at: "",
                  value: "0.00",
                  weight: 0,
                },
              ],
            },
          ],
        },
        courier: {
          contract_id: "",
          customer_full_name: "",
          ddp_cost: 0,
          dhl_time: null,
          dhl_type: false,
          freight_cost: 0,
          freight_value: 0,
          handling_time: 0,
          insurance_cost: 0,
          selectedQuote: null,
        },
        customer_postal_code: "",
        incoterm: "",
        is_landed_cost: null,
        currency: "USD",
      },
      selectedContract: "",
      quotes: {
        success: [],
        partial_success: [],
        error: [],
      },
      showQuotes: false,
      quickStarts: [],
      currency: "USD",
      errorQuoteCreate: "",
      allSteps: [
        {
          title: `1 - ${this.$t("orderCreatePage.receiver")}`,
          link: "receiver-information",
          formDone: false,
        },
        {
          title: `2 - ${this.$t("orderCreatePage.shipment")}`,
          link: "shipment-information",
          formDone: false,
        },
      ],
      isLoadingQuotes: false,
    };
  },
  beforeMount() {
    //this.getAndConfigureBoxes()
    quickstartService.getQuicks("quotes").then((response) => {
      const data = response.data.data;
      const finded = data.find((quickstart) => {
        return quickstart.action_type.split(",").includes("quotes");
      });
      if (finded) {
        const omit = [
          "created_at",
          "updated_at",
          "id",
          "seller_id",
          "action_type",
        ];
        for (const [key, value] of Object.entries(finded)) {
          if (!omit.includes(key) && value) {
            this.quickStartLoaded[key] = value;
          }
        }
        this.quote.origin_country = this.quickStartLoaded.origin_country || "";
        this.quote.incoterm = this.quickStartLoaded.incoterm;
        this.quote.insurance = this.quickStartLoaded.insurance || "";
        this.quote.category = this.quickStartLoaded.category;
        this.quote.currency = this.quickStartLoaded.currency || "USD";
        //this.quote.currency = this.quickStartLoaded.currency || 'USD';  <-----<< USE THIS CODE IN CASE OF GET QUICKSTART
        //this.quote.product.hsCode = this.quickStartLoaded.hs_code || '';
      }
    });
    this.loaded = false;
  },

  methods: {
    updateValidFields(validFieldId) {
      this.errors[validFieldId] = ""; //atualizar no front
      delete this.errors[validFieldId];
      if (
        this.errors &&
        this.errors.hasOwnProperty("volumes") &&
        this.errors.volumes.length < 1
      ) {
        delete this.errors.volumes;
      }
      if (
        this.errors &&
        this.errors.hasOwnProperty("products") &&
        this.errors.products.length < 1
      ) {
        delete this.errors.products;
      }
    },
    updateInvalidFields(invalidField) {
      this.errors = {
        ...this.errors,
        [invalidField.id]: [invalidField.message],
      };
      if (
        this.errors &&
        this.errors.hasOwnProperty("volumes") &&
        this.errors.volumes.length < 1
      ) {
        delete this.errors.volumes;
      }
      if (
        this.errors &&
        this.errors.hasOwnProperty("products") &&
        this.errors.products.length < 1
      ) {
        delete this.errors.products;
      }
      this.$emit("invalid");
    },
    formDoneChange(event, id) {
      this.allSteps.find((step) => step.link === id).formDone = event;
    },
    getQuotations() {
      this.isLoadingQuotes = true;
      let dataToSend = this.buildQuoteToSend();

      this.quotes = {
        success: [],
        partial_success: [],
        error: [],
      };
      this.showQuotes = false;
      quoteService
        .getQuotation(dataToSend)
        .then((response) => {
          this.isLoadingQuotes = false;
          if (
            response.data.data.success.length > 0 ||
            response.data.data.partial_success.length > 0 ||
            response.data.data.error.length > 0
          ) {
            this.errorQuoteCreate = "";
            this.currency = dataToSend.currency;

            this.quotes.success = response.data.data.success.map((obj) => ({
              ...obj,
              name: obj.contract_name,
              id: obj.contract_id,
            }));
            this.quotes.partial_success =
              response.data.data.partial_success.map((obj) => ({
                ...obj,
                name: obj.contract_name,
                id: obj.contract_id,
              }));
            this.quotes.error = response.data.data.error.map((obj) => ({
              ...obj,
              name: obj.contract_name,
              id: obj.contract_id,
            }));
            this.showQuotes = true;

            setTimeout(() => {
              const elmnt = document.getElementById("contracts-card");
              if (elmnt) elmnt.scrollIntoView({ behavior: "smooth" });
            }, 300);
          } else {
            this.errorQuoteCreate = "No contracts were found for this quote";
            setTimeout(() => {
              const elmnt = document.getElementById("contracts-card");
              if (elmnt) elmnt.scrollIntoView({ behavior: "smooth" });
            }, 300);
          }
          NProgress.done();
        })
        .catch(() => {
          this.errorQuoteCreate = "No contracts were found for this quote";
          this.isLoadingQuotes = false;
          NProgress.done();
        });
    },
    calculateVolumesWeight(volumes, err) {
      if (err) {
        return volumes.map((volume) => {
          volume.weight = volume.empty_weight;
          return volume;
        });
      } else {
        return volumes.map((volume) => {
          volume.empty_weight = volume.weight; //bug fix with total weight

          let total = parseFloat(volume.empty_weight || 0);
          if (volume.order_items_attributes) {
            volume.order_items_attributes.forEach((product) => {
              total +=
                parseFloat(product.weight || 0) *
                parseFloat(product.quantity || 0);
            });
          }
          volume.weight = total;
          return volume;
        });
      }
    },
    buildQuoteToSend(isError) {
      const formData = { ...this.quote };
      let quoteData = JSON.parse(JSON.stringify(formData));

      let builded = {
        customer_postal_code: quoteData.receiver.customer_postal_code,
        customer_country: quoteData.receiver.customer_country,
        customer_state: quoteData.receiver.customer_state,
        incoterm: quoteData.shipment.incoterm,
        is_landed_cost: quoteData.shipment.is_landed_cost,
        currency: quoteData.shipment.currency,
      };

      builded.volumes_attributes = this.calculateVolumesWeight(
        quoteData.shipment.volumes_attributes,
        isError
      );

      builded.volumes_attributes.forEach((element) => {
        element.weight = parseFloat(element.weight);
        element.width = parseFloat(element.width);
        element.height = parseFloat(element.height);
        element.length = parseFloat(element.length);
      });
      return builded;
    },
    createOrder() {
      let order = this.quote;

      this.$helpers.saveUnsavedWork("order-create", order);
      this.$router.push({
        name: "order_create",
        /* CASO SEJA FEITO ALGUM TRATAMENTO DO CREATE VINDO DO QUOTE.
          params: {
            isFrom: 'isClone',
          }
          */
      });
    },
    simplifyCurrency(currency) {
      if (currency === "USD") {
        return "$";
      } else {
        return "R$";
      }
    },
    hasReceiverErrorss(errors) {
      const usedShipmentKeys = ["customer"];
      return usedShipmentKeys.some((keyName) =>
        Object.keys(errors).some((errorKey) => errorKey.includes(keyName))
      );
      //return !!Object.keys(errors).find((keyName) => keyName.startsWith('customer'))
    },
    hasShipmentErrorss(errors) {
      const usedShipmentKeys = [
        "order_number",
        "return_insurance",
        "incoterm",
        "sales_channel_id",
        "total_weight",
        //'overpack_id', //overpack removido
        "total_value",
        "is_landed_cost",
      ];
      return !!Object.keys(errors).find((keyName) =>
        usedShipmentKeys.includes(keyName)
      );
    },
    hasBoxesErrorss(errors) {
      let finalResult = false;
      const filteredErrs = {};

      for (const key in errors) {
        if (!key.match(/^volumes\[\d+\]\.last_mile_number$/)) {
          filteredErrs[key] = errors[key];
        }
      }

      let autoTaxErr = !!Object.keys(filteredErrs).find((keyName) =>
        keyName.match(/volumes\[\d+\].(duties|sales)_tax_value/)
      );
      let anyVolumeErr = !!Object.keys(filteredErrs).find((keyName) =>
        keyName.startsWith("volumes")
      );
      let hsCodeErr = !!Object.keys(filteredErrs).find((error) =>
        error.startsWith("hs_code_")
      );
      let notAutoTaxErr = !!Object.keys(filteredErrs).find((keyName) =>
        keyName.match(/^volumes\[\d+\].((?!(duties|sales)_tax_value).)*$/)
      );

      if (autoTaxErr) {
        if (notAutoTaxErr || hsCodeErr) {
          finalResult = true;
        }
      } else {
        if (anyVolumeErr || hsCodeErr) {
          finalResult = true;
        } else {
          finalResult = false;
        }
      }
      return finalResult;
    },
    checkAllSteps() {
      let count = 0;
      this.allSteps.forEach((step) => {
        if (step.formDone) count++;
      });
      if (count >= this.allSteps.length) {
        return true;
      }
      return false;
    },
  },
  computed: {
    canGetQuotations() {
      /* const resultCheckLandedInconterm = this.checkLandedInconterm(); */
      if (this.isLoadingQuotes) {
        return false;
      }
      const resultCheckAllSteps = this.checkAllSteps();
      if (
        /* resultCheckLandedInconterm &&  */
        resultCheckAllSteps
      ) {
        return true;
      }
      return false;
    },
    sectionErros() {
      return {
        receiver: this.hasReceiverErrorss(this.errors),
        shipment: this.hasShipmentErrorss(this.errors),
        boxes: this.hasBoxesErrorss(this.errors),
      };
    },
    fieldsQuote() {
      return [
        {
          key: "freight_cost",
          label: this.$t("freightCost"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.freightCost",
            { val: this.$helpers.getPlatform() }
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "duties_tax",
          label: this.$t("dutiesTax"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.dutiesTax"
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "sales_tax",
          label: this.$t("salesTax"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.salesTax"
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "total_cost",
          label: this.$t("totalCost"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.totalCost"
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "delivery_time",
          label: this.$t("deliveryTime"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.deliveryTime"
          ),
          postpend: this.$t("trackMyOrderViewPage.days"),
        },
        /* { key: "id", label: 'ID' }, */
      ];
    },
  },
};
</script>
<style scoped>
.dashed-middle {
  width: 100%;
  position: relative;
}

.dashed-middle:before {
  content: "";
  position: absolute;
  bottom: 50%;
  border-bottom: 1px var(--gray-20) dashed;
  width: 100%;
  z-index: 0;
}
</style>
